<template>
  <div class="content">
    <headBar title="奖品领取确认" left-arrow @click-left="back" />
    <div>
      <div class="records-model">
        <div class="records-img">
          <div class="imgbg">
            <img :src="rewardObj.picUrl" alt="" />
          </div>
        </div>
        <div class="records-name">{{ rewardObj.awardName }}</div>
      </div>
      <div class="gg">
        <div class="title">领取门店</div>
        <div class="select" @click="showCV" :class="venueObJ.label ? 'black' : ''">
          {{ venueObJ && venueObJ.label || '请选择前往领取的门店' }}
          <van-icon name="arrow" class="to" />
        </div>
      </div>
      <div class="fb">
        <div class="t1">* 确认领取门店后不可修改</div>
        <div class="sub" @click="sure">确认领取</div>
        <div class="iphonex-pb"></div>
      </div>
    </div>
    <CityVenue ref="refCityVenue" :cityId="cityId" @setVenue="setVenue" />
  </div>
</template>

<script>
import headBar from '@/components/app/headBar'
import userMixin from '@/mixin/userMixin';
import wx from "weixin-js-sdk";
import CityVenue from "@/components/cl-city-venue/cityVenue.vue";
import { getParam } from '@/lib/utils'

export default {
  components: {
    headBar, CityVenue
  },
  mixins: [userMixin],
  data() {
    return {
      rewardObj: {},
      venueObJ: {}
    }
  },
  async created() {
    await this.$getAllInfo(['userId','token'])
    const { awardId, picUrl, awardName } = getParam()
    this.rewardObj = { awardId, picUrl: decodeURIComponent(picUrl), awardName: decodeURIComponent(awardName) }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    sure() {
      if (!this.venueObJ.id) {
        this.$toast('请选择门店')
        return
      }
      this.$axios.post(`${this.baseURLApp}/anniversary/sixth/receiveAward`, {
        userId: this.userId,
        awardId: this.rewardObj.awardId,
        venueId: this.venueObJ.id,
        venueName: this.venueObJ.label,
        token: this.token
      }).then((res) => {
        this.$toast('确认成功')
        setTimeout(() => {
          this.back()
        }, 2000);
      })

    },
    showCV() {
      this.$refs.refCityVenue.popShow = true;
    },
    setVenue(item) {
      this.venueObJ = item;
      console.log(item)
    },
  }
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}

.content {
  min-height: 100vh;
  // background-color: pink;
  padding-bottom: 30px;
  padding-bottom: calc(constant(safe-area-inset-bottom) + 30px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 30px);

  .records-model {

    .records-img {
      width: 200px;
      height: 200px;
      margin: 48px auto 19px;
      border-radius: 24px;
      background: linear-gradient(0deg, #FFB51D 0%, #F9624E 100%);
      padding: 8px;

      .imgbg {
        background: #FFF5E9;
        border-radius: 16px;
        overflow: hidden;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .records-name {
      font-size: 30px;
      font-weight: bold;
      color: #242831;
      text-align: center;
    }

  }

  .gg {
    margin-top: 150px;
    padding: 0 45px;

    .title {
      padding-left: 31px;
      color: #6C727A;
      font-size: 26px;
      margin-bottom: 21px;
    }

    .select {
      background: #F5F5F5;
      height: 100px;
      line-height: 100px;
      color: #6C727A;
      font-size: 30px;
      position: relative;
      margin-bottom: 70px;
      padding-left: 30px;
      border-radius: 8px;

      .to {
        position: absolute;
        right: 30px;
        top: 34px;
        font-size: 30px;
      }
    }

    .black {
      color: #242831;
    }
  }

  .fb {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px 45px;
    border-top: 1px solid #eee;
    background: #fff;

    .t1 {
      color: #68413C;
      font-size: 22px;
      text-align: center;
      margin-bottom: 20px;
    }

    .sub {
      border-radius: 8px;
      line-height: 96px;
      color: #242831;
      font-size: 32px;
      background: #FFDE00;
      text-align: center;
      font-weight: bold;
    }

    .gray {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>